import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as style from '../../component/productmaster.style';
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from 'shared/shared';
import RoleBackIcon from '@material-ui/icons/NavigateBefore';

import { useNavigate, useParams } from "react-router";
import { TablePagination, Typography } from "@material-ui/core";
import { useAppSelector, useAppDispatch } from "app/hook";
import { AppDispatch, reduxStore } from "app/redux_store";
import { useEffect, useState } from "react";
import { decrementTablePaginateNumber, incrementTablePaginateNumber, resetState, setArticleInformations, setIDeleteSingleProductInformation, setPageginatedNumberToOne, setProductValues } from "../redux/productmasterSearchCriterium.slice";
import { deleteSingleProductItem, getSearchCriterionDownload, getSearchCriterionFilterProducts, getSearchCriterionProductList } from "../redux/productmasterSearchCriterium.thunks";
import { IArticleMasterInformation, IPreSelectionProductInformation } from "./productmastgerSearchCriterium.model";
import SearchFieldComponent from "../../../../shared/components/search/searchfield.component";
import './productmastersearchCriterium.css';
import {  Menu, MenuItem } from "@material-ui/core";
import { DialogWindowOverviewComponentBasic } from "admin/productmaster/Delete/Dialog/dialogwindowoverviewBasic";
import { MenuOverviewComponent } from "shared/components/overview/menu_overview.component";
import { useTranslation } from "react-i18next";
import { decodeBase64CsvFile, getDate } from "./productmasterSearchCriterium.reducer";

export const ProductmasterSearchCriteriumOverviewTableOverviewComponent = () => {
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.productmasterSearchCriterium);
    const dispatch: AppDispatch = useAppDispatch();
    const { id } = useParams();
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [dialoge, setdialoge] = useState(false);
    const { t } = useTranslation();
    const [open, setOpen ] = useState(true);

    useEffect(() => {

        if (state?.data?.articleInformation) {
            const [idStr, articleTitle] = id.split('_');
            const articleInformation: IArticleMasterInformation= {
                id: Number(idStr),
                articleTitle,
                page: 1,
                size: 100,
            };

               
            
            dispatch(setArticleInformations(articleInformation));
        }
     },[dispatch,id, state?.data?.articleInformation]);


    useEffect(() => {
        return () => {
            dispatch(resetState())
           
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setProductValues())
        dispatch(getSearchCriterionProductList())
     
    },[dispatch]);


    if(state.data.productInformations.content.data.length===0 && state.command.getProductMasterTableSearchCriterion.status ==='pending'){
        return <LoadingIndicatorComponent />;
    }
    
     // Handler for page change
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
      if (newPage > state.data.tablePaginationPageNumber) {
          dispatch(incrementTablePaginateNumber())
          console.log('Next button clicked');
      } else if (newPage < state.data.tablePaginationPageNumber) {
          dispatch(decrementTablePaginateNumber())
          console.log('Back button clicked');
      }
      dispatch(getSearchCriterionProductList());
   
  };

  const returnEmptyarticleInformation =()=>{

    return(
    <>
        <Typography variant="h6">Keine Artikel gefunden</Typography>
    </>
    )
  }

  const handleDelete = () => {
    if (dialoge) {
        setdialoge(false);
    } else {
        setdialoge(true);
    }

    //dispatch(getProduct(selectedProducttunks));
}

  const onMenuClose = () => {
    setMenuAnchor(null);
};

  const returnArticleInformation=()=>{
    const onMenuOpen = (event, item: IPreSelectionProductInformation) => {
        dispatch(setIDeleteSingleProductInformation({id:Number( id.split('_')[0]), productInformation:item }))
        setMenuAnchor(event.currentTarget);
        
    };

    if( state.command.getProductMasterTableSearchCriterionFilter.status ==='pending'){
        return <LoadingIndicatorComponent />;
    }

    if( state.command.getProductMasterTableSearchCriterion.status ==='pending'){
        return <LoadingIndicatorComponent />;
    }

    return(
        <>
      
        <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={onMenuClose}
            >
               
                <MenuItem onClick={handleDelete}>
                    {t("button_remove_master")}

                    {dialoge ? <DialogWindowOverviewComponentBasic text ="Soll der Artikelstamm eintrag wirklich gelöscht werden" open={open} setOpen={(retval)=> setOpen(retval)} navi={""} value={null} title={t("dialogeWindow_Delete_Title")} callApi={()=>dispatch(deleteSingleProductItem())}  /> : <></>}
                </MenuItem>
                
            </Menu>
        
            <TableContainer>
                <Table className="Test" >
                    <TableHead>
                        <TableRow>
                            <style.StyledHeaderCell align="left" size='small' ></style.StyledHeaderCell>
                            {state.data.availableTableTitles.map((x)=> 
                            <style.StyledHeaderCell align="left" size='small' >{x.text}</style.StyledHeaderCell>
                            )}
                        </TableRow>
                    </TableHead>
                    {state.data.productInformations.content.data.map((e)=> 
                        <TableBody key={e.sku} >
                            <style.StyledTableRow key={e.sku} hover={true}>
                            <style.StyledMenuTableCell align="left" size='medium' >
                                    <MenuOverviewComponent onMenuOpen={onMenuOpen}
                                        menuItem={e} key={e.sku} />
                                </style.StyledMenuTableCell>
                            <style.StyledMenuTableCell align="left" size='small' >
                                    {e.sku}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.mainProductLine}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.productLine}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.productType}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.compressionClass}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.productionType}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.color}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.additionalDesign}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.foot}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.shortened}
                                </style.StyledMenuTableCell>
                                
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.extraWideCalf}
                                </style.StyledMenuTableCell>
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.compressivePantyTop}
                                </style.StyledMenuTableCell>
                                
                                <style.StyledMenuTableCell align="left" size='small' >
                                    {e.size}
                                </style.StyledMenuTableCell>
                           
                                </style.StyledTableRow>
                        </TableBody>
                )}

                </Table>
                
            
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[state.data.productInformations.content.pageCount]}
                component="div"
                count={state.data.productInformations.content.totalCount}
                rowsPerPage={state.data.productInformations.content.pageSize}
                page={state.data.tablePaginationPageNumber}
                onPageChange={handleChangePage}
            />       
        </>
        
    )
  }

  const filterTable=(filterText: string)=> {

    //check if filterText is empty
   // if(filterText.trim().length===0){
        // then set page to 1
        dispatch(setPageginatedNumberToOne())
    //}

    //also check if filterText have changed
    dispatch(getSearchCriterionFilterProducts(filterText))
  }
  

    const fileDownloader= async (): Promise<void> =>{

        // call api
         await dispatch(getSearchCriterionDownload());

        // Get the updated state AFTER the dispatch has completed
        const updatedState = reduxStore.getState(); //fresh state
        const csvFileData = updatedState.productmasterSearchCriterium.data.csvFileData;
        
        if(csvFileData){
            
            const generateFileName = `${state.data.articleInformation.articleTitle}_${getDate()}.csv`;
            let status= decodeBase64CsvFile(csvFileData,generateFileName);

            status ? console.log('success') : console.log('error');
        }
    }



    return (<>
     <FlexBox>
                <Left>
                <Typography variant="h4" >Artikelstamm - {state.data.articleInformation.articleTitle}</Typography>
                <SearchFieldComponent placeholder={"Suche"} onSearch={(filterText) => filterTable(filterText)}  />
                </Left>
                <Right>
                    <PrimaryButtonComponent onClick={()=>fileDownloader()}>CSV Download</PrimaryButtonComponent>
                    <PrimaryButtonComponent onClick={() => navigate("/home/productmaster")}>
                        <RoleBackIcon /> Zurück
                    </PrimaryButtonComponent>
                 
                </Right>
            </FlexBox>

            <br/>

            {state.data.productInformations.content.data.length===0 
            ?returnEmptyarticleInformation() 
            :returnArticleInformation()}
    </>); 
}

