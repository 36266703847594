import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux_store";
import { handleResponseErrors } from "../../../../shared/networking/error_handling";
import ProductmasterService from "services/productmaster.service";
import {IProductInformationId} from "../component/productmastgerSearchCriterium.model";



export const getSearchCriterionProductList = createAsyncThunk(
    'articletunks/articletunks/getSearchCriterionProductList',
    async (_, { getState }) => {
        const state = getState() as RootState
        let productInformationId : IProductInformationId ={
            id: state.productmasterSearchCriterium.data.articleInformation.id,
            size: state.productmasterSearchCriterium.data.articleInformation.size,
            page: state.productmasterSearchCriterium.data.articleInformation.page
        }
        const response = await ProductmasterService.GetSearchCriterionProductInformation(productInformationId)
        handleResponseErrors(response, "getSearchCriterionProductList");
        return response;
    }
);



export const deleteSingleProductItem = createAsyncThunk(
    'articletunks/articletunks/deleteSingleProductItem',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const response = await ProductmasterService.DeleteProductItem(state.productmasterSearchCriterium.data.deleteproductInformation)
        handleResponseErrors(response, "deleteSingleProductItem");
        return response;
    }
);

export const getSearchCriterionFilterProducts = createAsyncThunk(
    'articletunks/articletunks/getSearchCriterionFilterProducts',
    async (filterText: string, { getState }) => {
        const state = getState() as RootState
        let productInformationId : IProductInformationId ={
            id: state.productmasterSearchCriterium.data.articleInformation.id,
            filtertext: filterText,
            size: state.productmasterSearchCriterium.data.articleInformation.size,
            page: state.productmasterSearchCriterium.data.articleInformation.page
        }
        const response = await ProductmasterService.GetSearchCriterionProductInformation(productInformationId)
        handleResponseErrors(response, "getSearchCriterionFilterProducts");
        return response;
    }
);


export const getSearchCriterionDownload = createAsyncThunk(
    'articletunks/articletunks/getSearchCriterionDownload',
    async (_, { getState }) => {
        const state = getState() as RootState
        let productInformationId : IProductInformationId ={
            id: state.productmasterSearchCriterium.data.articleInformation.id,
           
        }
        const response = await ProductmasterService.GetDownloadProductInformation(productInformationId)
        handleResponseErrors(response, "getSearchCriterionDownload");
        return response;
    }
);

