
export const getDate=(): string=>{

    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0]; 
}


export const decodeBase64CsvFile=(data:string, fileName:string):boolean=>{
    try {
        const decodedCsv = atob(data); // Decode Base64
        const blob = new Blob([decodedCsv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return true;
      } catch (error) {
        console.error("Error decoding Base64 CSV:", error);
        return false;
      }
}