import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IDialogProps } from './IDialogeInterface';

export const DialogWindowOverviewComponentBasic = (props: IDialogProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClose = () => {
        props?.setOpen(false)
    };

    const deleteProductmaster = async () => {
        await handleClose();
        await props?.callApi();
        await window.location.reload();
    }

    const cancleProductmaster = () => {
        handleClose();
        navigate(props?.navi);
        props.setOpen(false)
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {props?.title}
                    {t("dialogeWindow_Delete_Title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {props?.text} {props.value}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteProductmaster} autoFocus>
                        {t("button_confirmation")}
                    </Button>
                    <Button onClick={cancleProductmaster} autoFocus>
                        {t("button_cancle")}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}