
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  ProductMasterSearchCriteriumModel } from "./productmasterSearchCriterium.model";
import { ProductValues } from '../../../../models/selectproductmanager/setproducts/productSelection_Type_Enum';
import { IArticleMasterInformation, IDeleteSingleProductInformation } from '../component/productmastgerSearchCriterium.model';
import { getSearchCriterionDownload, getSearchCriterionFilterProducts, getSearchCriterionProductList } from './productmasterSearchCriterium.thunks';
const initialState: ProductMasterSearchCriteriumModel = {
    loadedData: {
       
    },
    command: {
       // getProductMasterTable: { status: "idle", canExecute: false },
       getProductMasterTableSearchCriterion : {status:"idle", canExecute:false},
       getProductMasterTableSearchCriterionFilter : {status:"idle", canExecute:false}
    },
    data: {
        articleInformation: {
            articleTitle: '',
            id:0,
            size:100,
            page:1

        } , 
       availableTableTitles: [],
       csvFileData: null,
       productInformations: {

       content: {
                data: [],
                page: 0,
                pageCount: 0,
                pageSize: 0,
                totalCount:0
            },
            error: null,
            success:false
        
        },
       tablePaginationPageNumber:0,
       deleteproductInformation: {
        id:0,
        productInformation: {
            sku:"",
            mainProductLine:"",
            productLine:"",
            productType:"",
            productionType:"",
            compressionClass:"",
            compressivePantyTop:"",
            color:"",
            additionalDesign:"",
            foot:"",
            shortened:"",
            fullyKnitted:"",
            extraWideCalf:"",
            side:"",
            singlePiece:"",
            size:"",
            gender:"",
            quantityUnit:""

            
        }
       }
    },
   
    
}

export const productmasterSearchCriteriumCreateSlice = createSlice({
    name: 'getSearchCriterim',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data= initialState.data;
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
        },
        
        setArticleInformations: (state, action: PayloadAction<IArticleMasterInformation>) => {
            state.data.articleInformation = action.payload;
            
        },
        setProductValues: (state) => {

            state.data.availableTableTitles = ProductValues
            .filter((x) => x.value !== 0 && x.value !== 18 && x.value !== 19 && x.value !== 20 && x.value !== 10 && x.value !== 13 && x.value !== 15 && x.value !==16 && x.value !==17)
                .sort((a, b) => (a.value === 21 ? -1 : b.value === 21 ? 1 : 0));
        },
        incrementTablePaginateNumber: (state) => {
            state.data.tablePaginationPageNumber += 1;
            state.data.articleInformation.page = state.data.tablePaginationPageNumber + 1;
        },
        decrementTablePaginateNumber: (state) => {

            if(state.data.tablePaginationPageNumber>0){
                state.data.tablePaginationPageNumber -= 1;
            }

            if (state.data.articleInformation.page > 1) {
                state.data.articleInformation.page = state.data.tablePaginationPageNumber + 1;
            }
        },
        setIDeleteSingleProductInformation: (state, action : PayloadAction<IDeleteSingleProductInformation> )=> {
            
            state.data.deleteproductInformation = action.payload;
        },
       setPageginatedNumberToOne: (state) => {
        state.data.tablePaginationPageNumber =0;
        state.data.articleInformation.page = 0;
       }
    },

    extraReducers: (builder) => {
        builder.addCase(getSearchCriterionProductList.pending, (state) => {
         
           state.command.getProductMasterTableSearchCriterion.status ='pending'
        }).addCase(getSearchCriterionProductList.rejected, (state, action) => {
            state.command.getProductMasterTableSearchCriterion.status ='error'
        }).addCase(getSearchCriterionProductList.fulfilled, (state, action) => {
             state.command.getProductMasterTableSearchCriterion.status ='success'
            state.data.productInformations  = action.payload.getData();
           state.data.articleInformation.page = ++state.data.productInformations.content.page;
            //state.data.productInformations.flatMap((x)=> state.data.articleInformation.page = x.page)
        }).addCase(getSearchCriterionDownload.pending, (state) => {
         
            state.command.getProductMasterTableSearchCriterion.status ='pending'
         }).addCase(getSearchCriterionDownload.rejected, (state, action) => {
             state.command.getProductMasterTableSearchCriterion.status ='error'
         }).addCase(getSearchCriterionDownload.fulfilled, (state, action) => {
              state.command.getProductMasterTableSearchCriterion.status ='success'
             state.data.csvFileData  = action.payload.getData();
            //state.data.articleInformation.page = ++state.data.productInformations.content.page;
             //state.data.productInformations.flatMap((x)=> state.data.articleInformation.page = x.page)
         }).addCase(getSearchCriterionFilterProducts.pending, (state) => {
         
            state.command.getProductMasterTableSearchCriterionFilter.status ='pending'
         }).addCase(getSearchCriterionFilterProducts.rejected, (state, action) => {
             state.command.getProductMasterTableSearchCriterionFilter.status ='error'
         }).addCase(getSearchCriterionFilterProducts.fulfilled, (state, action) => {
              state.command.getProductMasterTableSearchCriterionFilter.status ='success'
              state.data.productInformations  = action.payload.getData();
            state.data.articleInformation.page = ++state.data.productInformations.content.page;
             //state.data.productInformations.flatMap((x)=> state.data.articleInformation.page = x.page)
         });

        
    }
})

export const {
    resetState,
    setArticleInformations,
    setProductValues,
    incrementTablePaginateNumber,
    decrementTablePaginateNumber,
    setIDeleteSingleProductInformation,
    setPageginatedNumberToOne
    
}= productmasterSearchCriteriumCreateSlice.actions

export default productmasterSearchCriteriumCreateSlice.reducer